<template>
<!-- eslint-disable -->
	<div style="height: 100vh">
		<h2 class="text-center" style="font-size: 28px!important; margin: 55px 0 40px 10px;">takenoを贈る</h2>
		<v-chip v-if="sendTo" class="sendTo" outlined close color="deep-purple accent-4" @click:close="sendTo = ''">
			{{ sendTo }}
		</v-chip>
		<v-autocomplete
			v-model="sendTo"
			v-if="!sendTo"
			:items="nameList"
			color="white"
			hide-no-data
			hide-selected
			label="送信先"
			style="max-width: 250px; margin-left: 75px;"
     	></v-autocomplete>
		<v-row class="mt-5 mb-10">
			<input v-model="amount" type="number" min="0" class="text-center amount" @input="checkAmount()">
			<span class="mt-7" style="font-size: 24px; position: absolute; right: 80px;">takeno</span>
		</v-row>
		<p class="text-center mt-3 ml-6 mb-7">※10から10000まで贈れます。</p>
		<v-textarea
			outlined
			name="report"
			label="メッセージ"
			v-model="message"
			height="250px"
			style="width:300px; margin: auto;"
			v-on:keydown.enter="enterDown()"
			v-bind:rules="upRules"
        ></v-textarea>
		<v-row>
			<v-btn class="mx-auto gf-btn" style="margin-top: 30px; width: 125px;" dark elevation="5" @click="popFlg = true">贈る</v-btn>
		</v-row>

		<v-dialog
			v-model="popFlg"
			persistent
			max-width="90%"
			style="z-index: 999;"
			>
			<v-card class="gradation">
				<v-icon @click="popFlg = false" size="30px" color="black" class="mt-2" style="position: absolute; right: 10px;">mdi-close-circle-outline</v-icon>
				<div class="text-center pt-8 pl-4">
					<div v-if="!returnFlg">
						<h4>以下の内容でよろしいですか？</h4>
						<p class="text-center mt-4" style="font-size: 20px;"><strong>{{ sendTo }}さん</strong>へ<br><strong>{{ amount }} takeno</strong>を贈る</p>
					</div>
					<p v-if="returnFlg" class="text-center mt-4">{{ message }}</p>
				</div>
				<v-card-actions>
					<v-btn block tile color="red" max-width="50" dark depressed small @click="popAct()" class="mb-2">
						確認
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</div>
</template>
<script>
import * as api from '@/api.js'
/* eslint-disable */
export default {
	name: 'Gift',
	data() {
		return{
			amount: 10,
			nameList: [],
			sendTo: '',

			message: '',
			upRules: [text => text.length <= 595 || '最大文字数は595文字です'],

			popFlg: false,
			returnFlg: false,
		}
	},
	methods: {
		load() {
			api.takeno.list().then((res) =>{
                switch(res.data.status) {
                    case 200:
						for(let i=0;i < res.data.content.length; i++){
							this.nameList.push(res.data.content[i]['name'])
						}
                        break
                    case 500:
						this.$router.push({ name: 'Home' })
                        break
                }
			}).catch()
		},


		gift() {
			api.takeno.gift({ sendTo: this.sendTo, amount: this.amount, message: this.message }).then((res) => {
				this.returnFlg = true
                switch(res.data.status) {
                    case 200:
						this.pop(res.data.content)
                        break
                    case 500:
						this.pop('未入力の必須項目があります。')
                        break
                }
			}).catch()
			this.popFlg = false
			this.load()
		},


		popAct() {
			if (this.returnFlg) {
				this.popFlg = false
				this.returnFlg = false
			}
			else { 
				this.gift()
			}
		},
		pop(message) {
			this.popFlg = true
			this.message = message
		},

		checkAmount() {
			if (this.takeno > 10000){
				if (this.amount > 10000) this.amount = 10000
			}
			else{
				if (this.amount > this.takeno) this.amount = this.takeno
			}
			if (this.amount < 0) this.amount = 0
		},
		enterDown() {
			return this.message.replace(/\\n/g,'\n')
		}
	},
	created() {
		this.load()
	},
	computed: {
		takeno() {
			return this.$store.getters.loginInfo.takeno
		}
	}
}
</script>
<style scoped>
.sendTo {
	margin-left: 110px;
	height: 35px;
	min-width: 180px;
	font-size: 18px;
	justify-content: center;
}

input:focus {
	/* border: 2px solid #5EADE4 !important; */
	outline: none;
}

.amount {
	margin: 10px 0 0 100px;
	font-size: 50px;
	height: 55px;
	width: 160px;
}

.gf-btn{
	background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 60%);
}
</style>
